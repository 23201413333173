<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        動肢動滋健康一起來
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="">
        <p class="image_desc">數據輔助訓練落實運動無障礙</p>
        <p class="image_desc">(圖片提供：夏姿運動)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          邀請花蓮縣肢體傷殘福利協進會及上肢殘疾的銀髮族參與計畫，進行為期8週的環狀運動訓練及相關運動檢測，過程中教練會依運動者身體狀態給予不同運動課程規劃，結合運動科技及環狀運動進行動作分析，記錄每位參與者的訓練動作過程。運動訓練中期，規劃獨木舟體驗及3D輕艇激流的檢測，再由教練評估帶領肢障者及銀髮長者，實際親水划獨木舟遊湖體驗。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          夏姿運動與花蓮縣肢體傷殘福利協進會舉辦【動肢動滋健康一起來】運動數據公益活動嘉年華，開放民眾實際體驗運動科技、3D輕艇激流、水上獨木舟等並同時上傳運動數據，現場讓肢障者與銀髮學員實際下水體驗獨木舟，展現訓練成效。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          通過運動數據的分析，導入市場應用模式和創新服務，促進運動參與和健康指標，提升特定族群健康水平和生活質量。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          推動健身場域優化、社區運動中心合作、教練培訓、課程導入、運動器材感測器等產業效益提升。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11208',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-08.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
